.separator {
    margin: 0 !important;
}

#stocks-finder {
    padding: 25px !important;
    height: 430px;
    min-height: 430px;
    overflow-y: scroll;
}

#main-container, #main-container{
    margin: 0;
}

h1.filter-tittle {
    line-height: 24px;
    font-size: 24px;
    margin: 0;
    display: inline-block;
    margin-top: 15px;
}

header {
    display: inline-block;
}

.separator > span.material-icons {
    font-size: 24px;
    line-height: 24px;
    float: right;
    margin-right: 50px;
    margin-top: 15px;
}

#stocks-finder {
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
}

#stocks-finder div {

}

.item-container {
    width: 50% !important;
    height: auto;
    padding: 3px;
}